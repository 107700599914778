import React, { useState, useEffect } from 'react'
import styles from "../../styles/kumkumadipdp.module.css";
import classes from "../../components/product/list-item.module.css";
import { parseCookies } from "nookies";
import * as ga from '../../lib/ga';
import { getWebsiteID } from '../../lib/common-functions';
import { useCookies } from 'react-cookie';
import { initializeStore } from '../store/store';
import desk_bag_white from '~/public/images/desk-bag-white.inline.svg';
import cancel_icon from '~/public/images/cancel-icon.inline.svg';

const ConditionalWrapper = ({ condition, children }) =>
    condition ? children : "";

async function homepageproducts(filters, key) {
    const response = await fetch("/api/products-query", {
        method: "POST",
        body: JSON.stringify({
            filters: filters,
            key: key,
        }),
        headers: {
            "Content-Type": "application/json",
        },
    });
    const data = await response.json();

    if (!response.ok) {
        return data;
    }

    return data;
}



const BuyNowButton = ({ index = 0, className, displayIcon = true, buttonText, pageId, sku, pageTitle = '', price='' }) => {
    const [ButtonText, setButtonText] = useState(buttonText)
    const [productData, setProductData] = useState({})
    const [showMe, setShowMe] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [storeConfig, setStoreConfig] = useState({});
    const [cPrice, setCPrice] = useState(productData?.price);
    const [mPrice, setMPrice] = useState(productData?.price);
    const [vPrice, setVPrice] = useState(instockVariantPrice(productData?.variants));
    const [showLoader, setShowLoader] = useState(false);
    const [cookie, setCookie] = useCookies(["store"]);
    const [utmsource, setUtmsource] = useCookies(["utm_source"]);
    const [open, setOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [cartMessage, setCartMessage] = useState('');
    const [bSnackbarVisible, setTriggerShowSnackbar] = useState(false);
    const reduxStore = initializeStore();
    const { dispatch } = reduxStore;

    let website_id = getWebsiteID(cookie);

    const cookies = parseCookies(null, "fromClient");

    const storeid = storeConfig.id || [];
    const currency = storeid == 2 ? "$" : "₹";

    function instockVariantPrice(variants) {
        let variantPrice = 0;
        if (variants && variants.length) {
            for (let i = 0; i < variants.length; i++) {
                if ((variants[i].product.stock_status == 'IN_STOCK')) {
                    variantPrice = variants[i].product.price_range.minimum_price.regular_price.value;
                    break;
                }
            }
        }
        return variantPrice;
    }

    useEffect(() => {
        (async () => {
            try {
                const productdata = await homepageproducts({ "sku": { "eq": sku } }, `${pageId}${sku}`)
                setStoreConfig(productdata?.data?.storeConfig);
                setProductData(productdata?.data?.products?.items?.[0]);
            } catch (error) {
                console.log('error in getting product data', error);
            }
        })();
    }, []);

    const addtobag = async (event) => {
        try {
            event.preventDefault();
            setShowLoader(true)
            setButtonText("Please Wait");
            const res = await fetch("/api/addtobag", {
                body: JSON.stringify({
                    parentsku:
                        event.target.parentsku && event.target.parentsku.value
                            ? event.target.parentsku.value
                            : "",
                    sku: event.target.sku.value,
                    qty: event.target.qty.value,
                    cookie: cookies,
                }),
                headers: {
                    "Content-Type": "application/json",
                },
                method: "POST",
            });

            const {
                id,
                name,
                price: {
                    regularPrice: {
                        amount: {
                            value: cPrice
                        }
                    }
                }
            } = productData
            ga.event({
                action: "add_to_cart",
                params: {
                    items: [
                        {
                            "id": '' + id,
                            "name": name,
                            "sku": event.target.sku.value,
                            "quantity": event.target.qty.value,
                            "price": '' + cPrice,
                            "content_id": event.target.sku.value,
                            "content_type": "product",
                            "content_name": name,
                            "google_business_vertical": "retail",
                            "currency": website_id == 1 ? "INR" : "USD"
                        }
                    ]
                }
            })

            const bagdata = await res.json();

            if (bagdata?.message) {
                setOpen(true);
                setSuccessMessage(false)
                setCartMessage(bagdata.message);
                setTriggerShowSnackbar(true);
                setShowLoader(false)
                setButtonText("Add to Bag");
            } else {
                setButtonText("Added");
                setShowLoader(false)
                setOpen(true);
                setSuccessMessage(true);
                let cartLink = (website_id == 1) ? "/checkout/cart" : "/international/checkout/cart"
                let cartMsg = "Product added to Bag! <a href=" + cartLink + ">VIEW BAG</a>"
                setCartMessage(cartMsg);
                setTriggerShowSnackbar(true);
                let Qty = (bagdata.total) ? (bagdata.total) : 1
                dispatch(
                    {
                        type: 'INCREMENT',
                        count: Qty,
                    })
                dispatch(
                    {
                        type: 'MINICARTTOTAL',
                        total_price: bagdata.grand_total,
                    })
                setButtonText("Add to Bag");

                //for times pixel
                if (utmsource['utm_source'] == 'Colombia') {
                    function setAttributes(element, attributes) {
                        Object.keys(attributes).forEach(attr => {
                            element.setAttribute(attr, attributes[attr]);
                        });
                    }
                    const attributes = {
                        id: 'timesimg',
                        height: '1',
                        width: '1',
                        style: 'display:none',
                    };
                    var img = document.createElement("img");
                    setAttributes(img, attributes);
                    img.src = "https://ade.clmbtech.com/cde/eventTracking.htm?pixelId=14381&_w=1&_t=1";
                    var element = document.querySelector('head');
                    element.appendChild(img);
                    img.remove();
                }
            }
        } catch (e) {
            console.log('error', e);
            setShowLoader(false);
        }
    };

    function toggle() {
        setShowMe(!showMe);
    }

    function isDefaultSelected(index) {
        if (selectedIndex == -1) {
            const value =
                productData?.variants[0].product.stock_status == "OUT_OF_STOCK" || (productData?.variants[0].product.salableqty.label &&
                    productData?.variants[0].product.salableqty.label <= 0)
                    ? productData?.variants[index].product.stock_status == "IN_STOCK"
                        ? true
                        : false
                    : index == 0
                        ? true
                        : false;
            return value;
        } else if (index == selectedIndex) {
            return true;
        } else return false;
    }

    const setPrice = async (event) => {
        const selectedsku = event.target.value;

        for (const [index, variant] of productData?.variants.entries()) {
            if (variant.product.sku == selectedsku) {
                setCPrice(variant.product.price.regularPrice.amount.value);
                setMPrice(variant.product.price_range.minimum_price.regular_price.value)
                setVPrice(variant.product.price_range.minimum_price.regular_price.value);
                setSelectedIndex(index);
            }
        }
    };
    return (
        productData?.stock_status == "IN_STOCK") && (
            <div className={`${className ? `${classes.btnbag} ${styles.btnbag_hydration} ${className}${index}` : `${classes.btnbag} ${styles.btnbag_kumkumadi}`}`}>
                <ConditionalWrapper
                    condition={(productData?.type_id == "simple" || productData?.configurable_options?.[0].values.length == 1) && (process.env.NEXT_PUBLIC_BTN && !process.env.NEXT_PUBLIC_BTN.split(',').includes(productData?.sku))}
                >
                    <form onSubmit={addtobag}>
                        <ConditionalWrapper condition={productData?.type_id == "configurable"}>
                            <input type="hidden" name="parentsku" value={productData?.parentsku} />
                            <input
                                type="hidden"
                                name="sku"
                                value={
                                    productData?.variants?.[0]?.product?.sku || ""
                                }
                            />
                        </ConditionalWrapper>
                        <ConditionalWrapper condition={productData?.type_id == "simple"}>
                            <input type="hidden" name="sku" value={productData?.sku} />
                        </ConditionalWrapper>

                        <input type="hidden" name="qty" value="1" />
                        <button type="submit">
                            {displayIcon && <img src={desk_bag_white} alt="bag icon" width="16" height={16} />}
                            {ButtonText} {pageTitle == 'routine' && ` - ${currency} ${price}`} 
                        </button>
                    </form>
                </ConditionalWrapper>
                <ConditionalWrapper
                    condition={productData?.type_id == "configurable" && productData?.configurable_options?.[0].values.length > 1 && (process.env.NEXT_PUBLIC_BTN && !process.env.NEXT_PUBLIC_BTN.split(',').includes(productData?.sku))}
                >
                    <form onSubmit={addtobag}>
                        <ConditionalWrapper condition={productData?.type_id == "configurable"}>
                            <input type="hidden" name="parentsku" value={productData?.sku} />
                        </ConditionalWrapper>
                        <input type="hidden" name="qty" value="1" />

                        <button
                            type="submit"
                            style={{
                                display: showMe ? "block" : "none",
                            }}
                        >
                            {displayIcon && <img src={desk_bag_white} alt="bag icon" width="16" height={16} />}
                            {ButtonText}
                        </button>

                        <button
                            // type="submit"
                            onClick={(e) => {
                                e.preventDefault();
                                toggle();
                            }}
                            style={{
                                display: showMe ? "none" : "block",
                            }}

                        >
                            {displayIcon && <img src={desk_bag_white} alt="bag icon" width="16" height={16} />}
                            {ButtonText}
                        </button>
                        <div
                            style={{
                                display: showMe ? "block" : "none",
                            }}
                            className={classes.view_size_colm}
                        >
                            <div className={classes.col}>
                                <h6>Choose size</h6>
                                <a className={classes.close} onClick={toggle}>
                                    <img src={cancel_icon} alt="close" />
                                </a>
                            </div>
                            <table>
                                <tbody>
                                    {productData?.configurable_options?.length > 0 &&
                                        productData?.variants.map((value, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="sku"
                                                            placeholder=""
                                                            value={productData?.variants[index].product.sku}
                                                            defaultChecked={isDefaultSelected(index)}
                                                            disabled={
                                                                productData?.variants[index].product.stock_status ==
                                                                    "OUT_OF_STOCK" || (productData?.variants[index].product.salableqty.label &&
                                                                        productData?.variants[index].product.salableqty.label <= 0)
                                                                    ? true
                                                                    : false
                                                            }
                                                            onClick={setPrice}
                                                        />
                                                        {productData?.variants[index].attributes[0]?.label}
                                                        <i>{productData?.variants[index].product.stock_status ==
                                                            "OUT_OF_STOCK" || (productData?.variants[index].product.salableqty.label &&
                                                                productData?.variants[index].product.salableqty.label <= 0)
                                                            ? ' Out of Stock'
                                                            : ''}</i>
                                                    </label>
                                                </td>
                                                <td>
                                                    {currency}
                                                    {
                                                        productData?.variants[index].product.price.regularPrice
                                                            .amount.value.toFixed(2)
                                                    }
                                                    {productData?.variants[index].product.price_range?.minimum_price.regular_price
                                                        .value > productData?.variants[index].product.price.regularPrice
                                                            .amount.value && <abbr className={classes.cutoffprice}>{currency}
                                                            {productData?.variants[index].product.price_range.minimum_price.regular_price
                                                                .value.toFixed(2)}</abbr>}
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </form>
                </ConditionalWrapper>
            </div>
        )
}

export default BuyNowButton